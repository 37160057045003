import React from "react"
import { PageProps, Link, graphql } from "gatsby"
import Img, { FixedObject } from "gatsby-image"
import Layout from "../components/layout"
import Pagination from "../components/Pagination"

interface Props {
  data: DataProps
  pageContext: PageContextProps
}

interface PageContextProps {
  limit: number
  start: number
  numPages: number
  currentPage: number
}

interface DataProps {
  allStrapiArticle: {
    edges: {
      node: {
        id: string
        title: string
        content: string
        excerpt: string
        image: {
          childImageSharp: {
            fluid: FixedObject
          }
        }
        slug: string
        category: {
          name: string
        }
        author: {
          name: string
        }
        published_at: string
      }
    }
  }
  pageContext: {
    currentPage: number
    limit: number
    start: number
    numOfPages: number
  }
}

export const query = graphql`
  query($start: Int!, $limit: Int!) {
    allStrapiArticle(
      sort: { fields: [published_at], order: DESC }
      limit: $limit
      skip: $start
    ) {
      edges {
        node {
          id
          title
          image {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          slug
          content
          excerpt
          category {
            name
          }
          author {
            name
          }
          published_at
        }
      }
    }
  }
`

const BlogList: React.FC<Props> = ({ data, pageContext }) => {
  const currentPage = pageContext.currentPage
  const numPages = pageContext.numPages
  const articles = data.allStrapiArticle.edges
  return (
    <Layout>
      <h1 className="font-monda">BLOG</h1>
      <div className="border-white border-b-4"></div>
      <Pagination currentPage={currentPage} numPages={numPages} type="blog" />
      <div className="grid grid-cols-1">
        {articles.map(article => {
          const excerpt = article.node.excerpt.slice(0, 170) + "..."
          return (
            <div
              key={article.node.id}
              className="my-4 border-b border-white grid pb-4 sm:grid-cols-3 sm:gap-4 "
            >
              <Img
                fluid={article.node.image.childImageSharp.fluid}
                alt={article.node.title}
                className="rounded  object-contain"
              />

              <div className="sm:col-start-2 sm:col-span-2">
                <h4 className="font-monda mt-4 mb-2 text-xl sm:mt-0">
                  <Link to={`/blog/${article.node.slug}`}>
                    {article.node.title}
                  </Link>
                </h4>

                <p className="text-sm font-mont lg:text-md">{excerpt}</p>
                <div className="flex flex-col  justify-between sm:flex-row sm:items-center">
                  <div>
                    <p className="my-0 font-mont text-sm font-bold">
                      {article.node.category.name}
                    </p>
                    <p className="my-0 font-mont text-sm">
                      {article.node.author.name} &#x272A; {}
                      {article.node.published_at}
                    </p>
                  </div>
                  <button className="bg-black text-white p-2 text-sm mt-2 sm:mt-0">
                    <Link
                      className="font-mont"
                      to={`/blog/${article.node.slug}`}
                    >
                      Read More &#xbb;
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <Pagination currentPage={currentPage} numPages={numPages} type="blog" />
    </Layout>
  )
}

export default BlogList
